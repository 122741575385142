<template>
  <div id="sync-garmin" class="box container">
    <p v-if="errorMessage" class="has-text-danger">{{ $t(errorMessage) }}</p>
  </div>
</template>

<script>

export default{
  name: 'SyncGarmin',
  data(){
    return {
      errorMessage: ''
    };
  },
  mounted: function(){
    var redirectLink = localStorage.getItem('redirectSyncLink') || '/settings';
    this.post('/garmin/sync_garmin', { oauth_verifier: this.$route.query.oauth_verifier }).then(() => {
      this.$router.push(redirectLink);
      this.$store.dispatch('reloadUserDataAfterSync');
    }).catch(err => {
      console.error(err);
      this.errorMessage = err;
      setTimeout(() => { this.$router.push(redirectLink); }, 2000);
    });
  },
}
</script>

<style lang="scss" scoped>
</style>
